export const environment = {
  production: true,
  apiUrl: "https://server.bdomobile.com/",
  tpayScript: "https://secure.tpay.com/groups-626021.js",
  keycloakUrl: "https://auth.mobiletract.com",
  realm: "bdo-mobile",
  clientId: "bdo-web-panel",
  googleTagManagerId: 'GTM-T43SC6BD',
  googleTagManagerPreview: 'env-1',
  googleTagManagerAuth: 'X1UGl1SdyR-9WUMI5abg3w'
};
